import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { CheckCircleIcon as CheckIcon } from '@heroicons/react/16/solid';

import { Alert, AlertActions, AlertBody, AlertTitle } from '@/component/basic/alert';
import { Button } from '@/component/basic/button';

import { type LibraryItem, type ResWithSuccess } from '@/type';
import { isNumberArrayEqual, sortLibraryItemByOrder } from '@/lib/utils';

export function SelectLibraryContainer({
  selectedLibraryIds,
  onSelectDone,
}: {
  selectedLibraryIds: number[];
  onSelectDone: (selecteId: number[]) => void;
}) {
  const { data } = useSWR<ResWithSuccess<LibraryItem[]>>(`/musics/libraries`);
  const [isAlertopen, setAlertOpen] = useState(false);
  const [libraryIds, setLibraryIds] = useState<number[]>(selectedLibraryIds);

  console.log(data?.data);

  const onClickClose = () => {
    setAlertOpen(false);
    setLibraryIds(selectedLibraryIds);
  };

  const onClickSelectDone = () => {
    setAlertOpen(false);
    onSelectDone(libraryIds);
  };

  useEffect(() => {
    setLibraryIds(selectedLibraryIds);
  }, [selectedLibraryIds]);

  return (
    <>
      <Alert className="relative" open={isAlertopen} onClose={() => {}}>
        <AlertTitle>라이브러리 선택</AlertTitle>
        <AlertBody className="flex flex-col gap-[1px] max-h-[50vh] overflow-y-scroll">
          {data?.data &&
            sortLibraryItemByOrder(selectedLibraryIds, data.data).map(library => (
              <LibrarySelectItem
                key={library.id}
                library={library}
                isSelected={libraryIds.includes(library.id)}
                onClick={() =>
                  setLibraryIds(prev =>
                    prev.includes(library.id) ? prev.filter(id => id !== library.id) : [...prev, library.id]
                  )
                }
              />
            ))}
        </AlertBody>
        <AlertActions>
          <Button plain onClick={onClickClose}>
            취소하기
          </Button>
          <Button disabled={isNumberArrayEqual(selectedLibraryIds, libraryIds)} onClick={onClickSelectDone}>
            저장하기
          </Button>
        </AlertActions>
      </Alert>
      <Button outline onClick={() => setAlertOpen(true)}>
        <div className="flex justify-between w-full">
          <div>라이브러리 선택하기</div>
          <div>{libraryIds.length}</div>
        </div>
      </Button>
    </>
  );
}

const LibrarySelectItem = ({
  library,
  isSelected,
  onClick,
}: {
  library: LibraryItem;
  isSelected: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      className={`flex flex-row justify-between items-center gap-2 w-full py-2 h-[70px] ${
        isSelected ? 'opacity-100' : 'opacity-40'
      }`}
      onClick={onClick}
    >
      {isSelected ? <CheckIcon className="size-5" /> : <div className="size-5 border border-black rounded-full" />}
      {/* <img src={library.musics?.[0]?.thumbnailUrl} alt="" className="w-[40px] h-[40px]" />
      <div className="flex flex-row flex-1 justify-between items-center">
        <div className="text-xs">{library.title}</div>
        <div className="text-xs">{!!library.musics.length && library.musics.length}</div>
      </div> */}
    </div>
  );
};
