import { useState } from 'react';
import axios from 'axios';
import { PencilIcon } from '@heroicons/react/24/solid';
import { Squares2X2Icon } from '@heroicons/react/24/outline';

import { config } from '@/config';
import { useAuth, useToast } from '@/hook';

import { Input } from '@/component/basic/input';

import { AuthorityLevel, type LibraryItem, type ResWithSuccess } from '@/type';
import { DeleteLibraryContainer } from './DeleteLibraryContainer';

type Props = {
  libraryItem: LibraryItem;
  update: () => void;
};

export function EditLibraryTitleContainer({ libraryItem, update }: Props) {
  const toast = useToast();
  const [input, setInput] = useState(libraryItem.title);
  const [isLoading, setLoading] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const { auth } = useAuth();

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleClose = () => {
    setEditing(false);
    setInput(libraryItem.title);
  };

  const handleClickPatchTitle = async () => {
    setLoading(true);

    try {
      const { data } = await axios.patch<ResWithSuccess<LibraryItem>>(
        `${config.api.host}/musics/libraries/${libraryItem.id}`,
        {
          title: input,
        }
      );

      if (data.data) {
        toast({ content: '수정 되었습니다.', type: 'success' });
        setEditing(false);
        update();
      }
    } catch (error) {
      toast({ content: '수정에 실패했습니다.', type: 'error' });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClickPatchPublic = async () => {
    setLoading(true);

    try {
      const { data } = await axios.patch<ResWithSuccess<LibraryItem>>(
        `${config.api.host}/musics/libraries/${libraryItem.id}`,
        {
          isPublic: !libraryItem.isPublic,
        }
      );

      if (data.data) {
        toast({ content: '수정 되었습니다.', type: 'success' });
        setEditing(false);
        update();
      }
    } catch (error) {
      toast({ content: '수정에 실패했습니다.', type: 'error' });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-row items-center gap-4 px-5 h-[60px] mx-1 bg-zinc-800 rounded-lg">
      {isEditing ? (
        <div className="flex flex-row w-full gap-4">
          <Input className="flex-1" value={input} onChange={handleChangeInput} />
          <div className="flex flex-row gap-4 text-xs text-white">
            <button className="disabled:opacity-30" disabled={isLoading} onClick={handleClose}>
              취소
            </button>
            <button
              className="disabled:opacity-30"
              disabled={isLoading || libraryItem.title === input}
              onClick={handleClickPatchTitle}
            >
              저장
            </button>
          </div>
        </div>
      ) : (
        <div className="flex flex-row w-full justify-between items-center text-white">
          <div className="flex flex-row gap-2 items-center text-sm">
            <Squares2X2Icon className="size-5" />
            {input}
            {auth?.authority?.level === AuthorityLevel.SUPER_ADMIN && (
              <div
                className="bg-white text-black px-2 py-1 border border-gray-200 shadow-md rounded-md cursor-pointer"
                onClick={handleClickPatchPublic}
              >
                {libraryItem.isPublic ? '공개' : '비공개'}
              </div>
            )}
          </div>
          {auth?.authority?.level === AuthorityLevel.SUPER_ADMIN && (
            <div className="flex flex-row items-center gap-4">
              <PencilIcon className="size-4 opacity-50 hover:opacity-100" onClick={() => setEditing(true)} />
              <DeleteLibraryContainer libraryId={libraryItem.id} libraryTitle={libraryItem.title} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
