import useSWR from 'swr';
import tw from 'tailwind-styled-components';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useLibraryLikeListStore, useMusicStore } from '@/store';
import { useAuth } from '@/hook';

import { CreateLibraryContainer } from '@/container/music';
import { MusicScrollLayout } from '@/layout';
import { LibraryListItem } from '@/component/custom';

import { AuthorityLevel, type LibraryItem, type ResWithSuccess } from '@/type';
import { ReactSortable } from 'react-sortablejs';
import axios from 'axios';
import { config } from '@/config';

const Container = tw.div`
  flex flex-col
  w-full h-full
`;

const findChangedOrderItems = (originalList: LibraryItem[], newList: LibraryItem[]) => {
  const changedItems: { id: number; order: number }[] = [];

  newList.forEach((newItem, newIndex) => {
    const originalItem = originalList.find(item => item.id === newItem.id);

    if (originalItem && originalItem.order !== newIndex + 1) {
      changedItems.push({
        id: newItem.id,
        order: newIndex + 1,
      });
    }
  });

  return changedItems;
};

export function LibraryListsPage() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { currentLibrary, selectedMusicIndex } = useMusicStore();
  const { data, mutate } = useSWR<ResWithSuccess<LibraryItem[]>>(`/libraries`);
  const [isLikeList, setIsLikeList] = useState(false);
  const { likeList, toggleLike } = useLibraryLikeListStore();
  const [libraryPublicList, setLibraryPublicList] = useState<LibraryItem[]>([]);
  const [libraryUnPublicList, setLibraryUnPublicList] = useState<LibraryItem[]>([]);

  const handleSuccessCreateLibrary = (createLibrary: LibraryItem) => {
    if (!data?.data) return;
    data?.data && mutate({ data: [createLibrary, ...data.data] });
  };

  const handleSortLibraryItems = async (newState: LibraryItem[]) => {
    const changedItems = findChangedOrderItems(libraryPublicList, newState);

    if (changedItems.length === 0) return;

    setLibraryPublicList(newState);

    try {
      await axios.patch(`${config.api.host}/libraries/order`, {
        state: changedItems,
      });
      mutate();
    } catch (error) {
      console.error('Failed to update the order', error);
    }
  };

  useEffect(() => {
    if (data?.data) {
      const libraryPublicList = data.data
        .filter(item => {
          return item.isPublic;
        })
        .sort((a, b) => (a.order && b.order ? a.order - b.order : 1));

      setLibraryPublicList(libraryPublicList);

      const libraryUnPublicList = data.data.filter(item => {
        return !item.isPublic;
      });

      setLibraryUnPublicList(libraryUnPublicList);
    }
  }, [data]);

  return (
    <Container>
      <div className="flex flex-row w-full h-[40px] text-sm">
        <div
          className={`flex justify-center items-center flex-1 border-b ${
            isLikeList ? 'bg-zinc-200 opacity-40 border-white' : 'bg-white border-zinc-200'
          }`}
          onClick={() => setIsLikeList(false)}
        >
          All
        </div>
        <div
          className={`flex justify-center items-center flex-1 border-b  ${
            isLikeList ? 'bg-white border-zinc-200' : 'bg-zinc-200 opacity-40 border-white'
          }`}
          onClick={() => setIsLikeList(true)}
        >
          Likes
        </div>
      </div>
      <MusicScrollLayout>
        {isLikeList ? (
          !likeList.length ? (
            <div className="px-4 h-full flex justify-center items-center text-sm">
              좋아요 목록이 없습니다. 추가해주세요.
            </div>
          ) : (
            data?.data
              .filter(libraryItem => likeList.includes(libraryItem.id))
              .map(libraryItem => (
                <LibraryListItem
                  key={libraryItem.id}
                  libraryItem={libraryItem}
                  isPlaying={currentLibrary?.route === `/musics/libraries/${libraryItem.id}`}
                  isLiked={likeList.includes(libraryItem.id)}
                  toggleLike={() => toggleLike(libraryItem.id)}
                  onClickItem={() => navigate(`/musics/libraries/${libraryItem.id}`)}
                />
              ))
          )
        ) : (
          <>
            <ReactSortable
              list={libraryPublicList}
              setList={handleSortLibraryItems}
              delay={500}
              delayOnTouchOnly={true}
              disabled={auth?.authority.level !== AuthorityLevel.SUPER_ADMIN}
            >
              {libraryPublicList.map(libraryItem => (
                <LibraryListItem
                  key={libraryItem.id}
                  libraryItem={libraryItem}
                  isPlaying={currentLibrary?.route === `/musics/libraries/${libraryItem.id}`}
                  isLiked={likeList.includes(libraryItem.id)}
                  toggleLike={() => toggleLike(libraryItem.id)}
                  onClickItem={() => navigate(`/musics/libraries/${libraryItem.id}`)}
                />
              ))}
            </ReactSortable>
            {auth?.authority.level === AuthorityLevel.SUPER_ADMIN &&
              libraryUnPublicList.map(libraryItem => (
                <LibraryListItem
                  key={libraryItem.id}
                  libraryItem={libraryItem}
                  isPlaying={currentLibrary?.route === `/musics/libraries/${libraryItem.id}`}
                  isLiked={likeList.includes(libraryItem.id)}
                  toggleLike={() => toggleLike(libraryItem.id)}
                  onClickItem={() => navigate(`/musics/libraries/${libraryItem.id}`)}
                />
              ))}
          </>
        )}
      </MusicScrollLayout>
      {selectedMusicIndex === null && auth?.authority.level === AuthorityLevel.SUPER_ADMIN && (
        <div className="flex px-4 py-8">
          <CreateLibraryContainer onSuccess={handleSuccessCreateLibrary} />
        </div>
      )}
    </Container>
  );
}
