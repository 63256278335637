import { MusicalNoteIcon, SpeakerWaveIcon } from '@heroicons/react/24/solid';
import { HeartIcon } from '@heroicons/react/16/solid';

import type { LibraryItem } from '@/type';
import { useAuth } from '@/hook';

type Props = {
  libraryItem: LibraryItem;
  isPlaying: boolean;
  isLiked: boolean;
  toggleLike: () => void;
  onClickItem: () => void;
};

export function LibraryListItem({ libraryItem, isPlaying, isLiked, toggleLike, onClickItem }: Props) {
  const { auth } = useAuth();

  return (
    <div
      className={`select-none relative flex flex-row justify-between items-center gap-4 w-full px-4 py-2 h-[70px] ${
        isPlaying ? 'bg-zinc-200' : 'bg-white'
      }`}
      onClick={onClickItem}
    >
      <div className="relative">
        {libraryItem.musicLibraries?.[0] ? (
          <img
            src={libraryItem.musicLibraries.sort((a, b) => a.order - b.order)[0].music.thumbnailUrl}
            alt=""
            className="w-[50px] h-[50px] object-cover"
          />
        ) : (
          <div className="flex justify-center items-center w-[50px] h-[50px] bg-zinc-100">
            <MusicalNoteIcon className="w-[30px] h-[30px]" />
          </div>
        )}
        {isPlaying && (
          <div className="absolute top-0 left-0 w-[50px] h-[50px] bg-white/30 flex justify-center items-center">
            <SpeakerWaveIcon className="size-5 animate-pulse" />
          </div>
        )}
      </div>
      <div className="flex flex-row flex-1 gap-x-2 justify-between items-center">
        {auth?.authority.level === 'SUPER_ADMIN' && (
          <div className="flex flex-row gap-x-4 items-center">
            <div style={{ background: libraryItem.color }} className="w-[8px] h-[8px] rounded-full" />
            {!libraryItem.isPublic && (
              <div className="text-[10px] px-2 py-1 rounded-md border border-gray-200 shadow-lg">비공개</div>
            )}
          </div>
        )}

        <div className="flex-1">{libraryItem.title}</div>
        <div className="flex flex-row gap-4 items-center">
          {/* <div className="text-sm">{!!libraryItem.musics.length && libraryItem.musics.length}</div> */}
          <HeartIcon
            className={`size-5 ${isLiked ? 'opacity-100' : 'opacity-30'}`}
            onClick={e => {
              e.stopPropagation();
              toggleLike();
            }}
          />
        </div>
      </div>
    </div>
  );
}
