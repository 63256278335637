import { useState } from 'react';
import { MusicalNoteIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { MegaphoneIcon } from '@heroicons/react/24/outline';

import { useAuth } from '@/hook';

import { EnterDialogContainer } from '@/container/common';

import { useMusicStore } from '@/store';

export function LandingPage() {
  const navigate = useNavigate();
  const { auth, logout } = useAuth();
  const [logoLoaded, setLogoLoaded] = useState(false);
  const { selectedMusicIndex, currentLibrary } = useMusicStore();

  const handleClickEnter = (openLoginDialog: () => void) => () => {
    if (auth) {
      navigate('/musics/libraries');
      // if (auth.authority.level === AuthorityLevel.SUPER_ADMIN) {
      //   navigate('/musics/');
      // } else {
      //   navigate('/musics/libraries');
      // }
    } else {
      openLoginDialog();
    }
  };

  const handleClickLogout = () => {
    if (auth && window.confirm('로그아웃 하시겠습니까?')) {
      logout();
    }
  };

  return (
    <div className="flex flex-col w-full h-full pt-[100px] items-center">
      <img
        src="/logo.jpeg"
        alt="logo"
        className={`w-[100px] p-2 rounded-full ${auth ? 'animate-pulse' : ''}`}
        onLoad={() => setLogoLoaded(true)}
        onClick={handleClickLogout}
      />
      <div className="text-sm nanum-myeongjo-bold tracking-[1px]">Playlist</div>
      {logoLoaded && (
        <EnterDialogContainer>
          {({ openDialog }) => (
            <div
              className="relative flex justify-center items-center w-full h-full"
              onClick={handleClickEnter(openDialog)}
            >
              <div className="absolute rounded-full p-3 bg-zinc-100 animate-wave">
                {selectedMusicIndex ? (
                  <MegaphoneIcon
                    className="size-6 animate-pulse text-black/70"
                    onClick={() => currentLibrary && navigate(currentLibrary?.route)}
                  />
                ) : (
                  <MusicalNoteIcon className="size-6 fill-black cursor-pointer" />
                )}
              </div>
            </div>
          )}
        </EnterDialogContainer>
      )}
    </div>
  );
}
