import { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlayIcon, PauseIcon, ForwardIcon, BackwardIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Range } from 'react-range';
import { PiRepeatOnce, PiRepeat } from 'react-icons/pi';
import axios from 'axios';

import { parseTime } from '@/lib/utils';
import type { LibraryState, MusicItem } from '@/type';
import { config } from '@/config';

type Props = {
  currentLibrary: LibraryState;
  currentPlayList: MusicItem[];
  selectedMusicIndex: number;
  isDetailClicked: boolean;
  isInvisible: boolean;
  setSelectedMusicIndex: (index: number | null) => void;
  setIsDetailClicked: (isClicked: boolean) => void;
  closePlayer: () => void;
};

export const AudioPlayer = ({
  currentLibrary,
  currentPlayList,
  selectedMusicIndex,
  isInvisible,
  setSelectedMusicIndex,
  setIsDetailClicked,
  closePlayer,
}: Props) => {
  const navigate = useNavigate();
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isOpenControler, setOpenControler] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isRepeat, setRepeat] = useState(false);
  const [isOnce, setOnce] = useState(false);
  const [isCycleEnd, setCycleEnd] = useState(false);
  const [triggerCount, setTriggerCount] = useState(false);

  const handleClickNavigateCurrentPlayLibrary = () => navigate(currentLibrary.route);

  const handleClickClosePlayer = useCallback(() => {
    closePlayer();
  }, [closePlayer]);

  const toggleOpenControler = useCallback(() => {
    setOpenControler(!isOpenControler);
    setIsDetailClicked(!isOpenControler);
  }, [isOpenControler, setIsDetailClicked]);

  const handleRangeChange = useCallback(
    (values: number[]) => {
      if (audioRef.current) {
        const newTime = (values[0] / 100) * duration;
        audioRef.current.currentTime = newTime;
        setCurrentTime(newTime);
      }
    },
    [duration]
  );

  const togglePlayPause = useCallback(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  }, [isPlaying]);

  const toggleRepeat = useCallback(() => {
    if (isOnce) {
      setOnce(false);
      setRepeat(false);
      return;
    }

    if (isRepeat) {
      setOnce(true);
      return;
    }
    setRepeat(!isRepeat);
    setCycleEnd(false);
  }, [isOnce, isRepeat]);

  const prevTrackHandler = () => {
    if (isRepeat) {
      setSelectedMusicIndex(selectedMusicIndex - 1 >= 0 ? selectedMusicIndex - 1 : currentPlayList.length - 1);
    } else {
      if (selectedMusicIndex === 0) {
        return;
      }
      setSelectedMusicIndex(selectedMusicIndex - 1);
    }
  };

  const nextTrackHandler = () => {
    if (isRepeat) {
      setSelectedMusicIndex(selectedMusicIndex + 1 >= currentPlayList.length ? 0 : selectedMusicIndex + 1);
    } else {
      if (selectedMusicIndex === currentPlayList.length - 1) {
        setCycleEnd(true);
        return;
      }
      setSelectedMusicIndex(selectedMusicIndex + 1);
    }
  };

  useEffect(() => {
    const audio = audioRef.current;

    if (audio) {
      const playHandler = () => {
        if (isCycleEnd) {
          return;
        }

        audio.play().catch(error => console.log('Play interrupted:', error));
        setDuration(audio.duration);
        setIsPlaying(true);
        setCycleEnd(false);
      };

      const pauseHandler = () => {
        audio.pause();
        setIsPlaying(false);
      };

      const endedHandler = () => {
        pauseHandler();
        audio.currentTime = 0;
        setCurrentTime(0);

        if (isOnce) {
          setTriggerCount(!triggerCount);
          setSelectedMusicIndex(selectedMusicIndex);
          return;
        }
        nextTrackHandler();
      };

      const timeUpdateHandler = () => {
        setCurrentTime(audio.currentTime);
      };

      const loadedMetadataHandler = () => {
        setDuration(audio.duration);
      };

      if ('mediaSession' in navigator) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title: currentPlayList[selectedMusicIndex].title,
          artist: currentPlayList[selectedMusicIndex].author,
          album: '요가투더피플 플레이 리스트',
          artwork: [
            { src: currentPlayList[selectedMusicIndex].thumbnailUrl, sizes: '96x96', type: 'image/png' },
            { src: currentPlayList[selectedMusicIndex].thumbnailUrl, sizes: '128x128', type: 'image/png' },
            { src: currentPlayList[selectedMusicIndex].thumbnailUrl, sizes: '192x192', type: 'image/png' },
            { src: currentPlayList[selectedMusicIndex].thumbnailUrl, sizes: '256x256', type: 'image/png' },
            { src: currentPlayList[selectedMusicIndex].thumbnailUrl, sizes: '384x384', type: 'image/png' },
            { src: currentPlayList[selectedMusicIndex].thumbnailUrl, sizes: '512x512', type: 'image/png' },
          ],
        });

        navigator.mediaSession.setActionHandler('play', playHandler);
        navigator.mediaSession.setActionHandler('pause', pauseHandler);
        navigator.mediaSession.setActionHandler('previoustrack', prevTrackHandler);
        navigator.mediaSession.setActionHandler('nexttrack', nextTrackHandler);
      }

      audio.addEventListener('canplaythrough', playHandler);
      audio.addEventListener('timeupdate', timeUpdateHandler);
      audio.addEventListener('loadedmetadata', loadedMetadataHandler);
      audio.addEventListener('ended', endedHandler);

      return () => {
        if ('mediaSession' in navigator) {
          navigator.mediaSession.setActionHandler('play', null);
          navigator.mediaSession.setActionHandler('pause', null);
          navigator.mediaSession.setActionHandler('previoustrack', null);
          navigator.mediaSession.setActionHandler('nexttrack', null);
        }
        audio.removeEventListener('timeupdate', timeUpdateHandler);
        audio.removeEventListener('loadedmetadata', loadedMetadataHandler);
        audio.removeEventListener('ended', endedHandler);
        audio.removeEventListener('canplaythrough', playHandler);
      };
    }
  }, [
    selectedMusicIndex,
    currentPlayList,
    isOnce,
    triggerCount,
    isRepeat,
    isCycleEnd,
    nextTrackHandler,
    prevTrackHandler,
    setSelectedMusicIndex,
  ]);

  useEffect(() => {
    const audio = audioRef.current;

    const audioUrl = currentPlayList[selectedMusicIndex].audioUrl;

    if (!audioUrl) {
      return setSelectedMusicIndex(selectedMusicIndex + 1);
    }

    if (audio) {
      audio.src = audioUrl;
      audio.load();

      return () => {
        audio.pause();
        setIsPlaying(false);
      };
    }
  }, [selectedMusicIndex, currentPlayList, setSelectedMusicIndex]);

  // useEffect(() => {
  //   (async function () {
  //     try {
  //       await axios.patch(
  //         `${config.api.host}/musics/${currentPlayList[selectedMusicIndex].id}/count`,
  //         {},
  //         {
  //           headers: {
  //             authorization: `Bearer ${localStorage.getItem(config.token.name.access)}`,
  //           },
  //         }
  //       );
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })();
  // }, [currentPlayList, selectedMusicIndex, triggerCount]);

  return (
    <div
      className={`absolute left-1 right-1 bottom-1 flex-1 h-fit pb-4 bg-zinc-800 text-white rounded-lg overflow-hidden ${
        isInvisible ? 'hidden' : ''
      }`}
    >
      <audio ref={audioRef} src={currentPlayList[selectedMusicIndex].audioUrl} />
      <div
        className="px-4 p-2 text-[10px] text-zinc-400 cursor-pointer"
        onClick={handleClickNavigateCurrentPlayLibrary}
      >
        {currentLibrary.title}
      </div>
      <div className="flex flex-row items-center px-4 h-[70px] gap-4">
        <img src={currentPlayList[selectedMusicIndex].thumbnailUrl} alt="" className="w-[50px] aspect-square" />
        <div className="flex flex-1 gap-1">
          <div className="flex flex-col justify-center gap-1 flex-1 h-full" onClick={toggleOpenControler}>
            <div className="w-full truncate-multiline text-xs font-semibold">
              {currentPlayList[selectedMusicIndex].title}
            </div>
            <div className="w-full text-xs text-zinc-400">{currentPlayList[selectedMusicIndex].author}</div>
          </div>
          <div className="flex flex-row justify-between items-center gap-2">
            <div className="p-1" onClick={togglePlayPause}>
              {isPlaying ? <PauseIcon className="size-5" /> : <PlayIcon className="size-5" />}
            </div>
            <div className="p-1" onClick={handleClickClosePlayer}>
              <XMarkIcon className="size-5" />
            </div>
          </div>
        </div>
      </div>

      {!isOpenControler ? (
        <div className="relative w-full h-[1px] bg-white">
          <div
            style={{
              position: 'absolute',
              width: `${(Math.floor(currentTime) / Math.floor(duration)) * 100}%`,
              height: '1px',
              backgroundColor: 'red',
            }}
          ></div>
        </div>
      ) : (
        <div className="flex flex-col justify-center">
          <div className="flex flex-col w-full px-8 pt-6 gap-2">
            <Range
              step={0.1}
              min={0}
              max={100}
              values={[(currentTime / duration) * 100]}
              onChange={handleRangeChange}
              renderTrack={({ props, children }) => {
                return (
                  <div {...props} className="h-[5px] w-full bg-zinc-50/30">
                    {children}
                  </div>
                );
              }}
              renderThumb={({ props }) => {
                const { key, ...restProps } = props;
                return <div key={key} {...restProps} className="w-[14px] p-1 aspect-square rounded-full bg-zinc-100" />;
              }}
            />
            <div className="flex flex-row justify-between text-[10px] text-zinc-50/50">
              <div>{parseTime(Math.floor(currentTime))}</div>
              <div>{parseTime(Math.floor(duration))}</div>
            </div>
          </div>
          <div className="flex flex-col justify-center">
            <div className="relative flex flex-row justify-center items-center px-8">
              <div className="flex flex-row justify-center items-center">
                <button
                  className="disabled:opacity-30"
                  disabled={!isRepeat && !isOnce && selectedMusicIndex === 0}
                  onClick={prevTrackHandler}
                >
                  <BackwardIcon className="size-8" />
                </button>
                <div className="p-1" onClick={togglePlayPause}>
                  {isPlaying ? <PauseIcon className="size-12" /> : <PlayIcon className="size-12" />}
                </div>
                <button
                  className="disabled:opacity-30"
                  disabled={!isRepeat && !isOnce && selectedMusicIndex === currentPlayList.length - 1}
                  onClick={nextTrackHandler}
                >
                  <ForwardIcon className="size-8" />
                </button>
              </div>
              <div className={'absolute right-8'} onClick={toggleRepeat}>
                {isOnce ? (
                  <PiRepeatOnce className="size-6" />
                ) : (
                  <PiRepeat className={`size-6 ${isRepeat ? 'opacity-100' : 'opacity-30'}`} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AudioPlayer;
