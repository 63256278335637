import useSWR from 'swr';
import { useEffect, useState } from 'react';

import { useAuthStore } from '@/store';
import { config } from '@/config';
import { useToast } from '@/hook/useToast';

import { ResWithSuccess, ResWithError, Res, AuthModel } from '@/type';

export const isResHasError = (data: object) => data.hasOwnProperty('error');

export function handleResponse<T>(
  res: Res<T>,
  {
    onSucces,
    onError,
  }: {
    onSucces: (data: ResWithSuccess<T>) => void;
    onError: (error: ResWithError) => void;
  }
) {
  if (isResHasError(res)) {
    const error = res as ResWithError;
    onError(error);
  } else {
    const data = res as ResWithSuccess<T>;
    onSucces(data);
  }
  return res;
}

type UseAuthReturn = {
  auth?: AuthModel | null;
  error?: ResWithError;
  login: (accessToken: string) => void;
  logout: () => void;
};

export function useAuth(): UseAuthReturn {
  const toast = useToast();
  const { auth, setAuth } = useAuthStore();
  const { data, mutate } = useSWR<ResWithSuccess<AuthModel>>('/auth/me');
  const [error, setError] = useState<ResWithError>();

  const login = (accessToken: string) => {
    localStorage.setItem(config.token.name.access, accessToken);
    toast({ content: '행복한 하루 되세요♪♫', type: 'success' });
    mutate();
  };

  const logout = () => {
    localStorage.removeItem(config.token.name.access);
    setAuth(null);
    toast({ content: '로그아웃 되었습니다.', type: 'success' });
    mutate(undefined);
  };

  useEffect(() => {
    if (data) {
      handleResponse(data, {
        onSucces(data) {
          if (!auth) {
            setAuth(data.data);
          }

          if (error) {
            setError(undefined);
          }
        },
        onError(err) {
          if (!error) {
            setError(err);
          }

          if (auth) {
            setAuth(null);
          }
        },
      });
    }
  }, [data, error, auth, setAuth, toast]);

  return {
    auth,
    error,
    login,
    logout,
  };
}
